<template>
  <page-content :show-search="is_admin">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="true"
        :search-tips="$t('')"
      />

      <table-content
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :per-page="page_condition.pageSize"
        :enable-pagination="false"
        @runTableAction="runTableAction"
        @mousedownAction="mousedownAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('bedfloor'),
      is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Bed_Floor_List'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        // pageSize: common.getTablePerPage(),
        pageSize: 9999,
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'common.floor',
          field: 'floorName',
          sortable: false,
        },
        {
          label: 'common.room',
          field: 'roomNames',
          sortable: false,
          change: function (roomNamesArr) {
            return roomNamesArr.join(', ')
          },
          // changeHTML(roomNames) {
          //   return roomNames.map(room => {
          //     return `<span class="badge mr-1 badge-dark">${room}</span>`
          //   }).join('')
          // }
        },
        {
          label: 'floor.isActive',
          field: 'isActive',
          sortable: false,
          width: '100px',
          change: function (isActive) {
            return isActive ? common.getI18n('common.enable') : common.getI18n('common.disable')
          }
        },
        {
          label: 'common.action',
          field: 'action',
          width: '300px',
          sortable: false,
        },
      ],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Bed_Floor_Create'
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Bed_Floor_View'
        },
        {
          text: 'common.up',
          variant: 'secondary',
          fun: 'upRow',
          permission: 'Bed_Floor_Edit',
          show: 'this.rows[0].floorID !== row.floorID',
          // check_disable(vm, cell) {
          //   return vm.rows[0].floorID === cell.row.floorID
          // },
        },
        {
          text: 'common.down',
          variant: 'secondary',
          fun: 'downRow',
          permission: 'Bed_Floor_Edit',
          show: 'this.rows[this.rows.length - 1].floorID !== row.floorID',
          // check_disable(vm, cell) {
          //   return vm.rows[vm.rows.length - 1].floorID === cell.row.floorID
          // },
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
    }
  },
  mounted() {
    if (!common.isAdmin()) {
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      this.getList()
    }
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/bedfloor/GetFloorList'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.floors
      })
    },
    addData: function () {
      this.$router.push({name: 'bedfloor_add'})
    },
    editData: function (data) {
      this.$router.push({name: 'bedfloor_edit', params: {id: common.encrypt(data.floorID)}})
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    upRow: function (data) {
      this.changRowOrder('up', data)
    },
    downRow: function (data) {
      this.changRowOrder('down', data)
    },
    changRowOrder: function (type, data) {
      data.selected = true
      let rows = JSON.parse(JSON.stringify(this.rows))

      for (let i in rows) {
        i = parseInt(i)

        delete rows[i].selected
        if (rows[i].floorID === data.floorID) {
          let tmp = {}
          if (type === 'up') {
            tmp = rows[i - 1]
            rows[i - 1] = data
          } else {
            tmp = rows[i + 1]
            rows[i + 1] = data
          }

          rows[i] = tmp
          break
        }
      }

      this.rows = rows
      let seqs = []
      for (let i in this.rows) {
        i = parseInt(i)
        seqs.push({floorID: this.rows[i].floorID, seq: i + 1})
      }

      common.apiPostData('/bedfloor/UpdateFloorSeq', {facilityID: common.getFacilityId(), floorSeqs: seqs}).then(res => {

      })
    },
    mousedownAction: function (data) {
      // console.log('mousedown')
      // let rows = JSON.parse(JSON.stringify(this.rows))
      // for (const i in rows) {
      //   delete rows[i].selected
      //   if (rows[i].floorID === data.floorID) {
      //     rows[i].selected = true
      //   }
      // }
      // this.rows = rows
    }


  }
}
</script>
